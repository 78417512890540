<!--
 * @Author: lzh
 * @Date: 2022-12-13 11:03:27
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-12 16:58:18
 * @Description: file content
-->
<template>
  <div class="edit-text-ricePOS-box">
    <h3>描述上間距</h3>
    <el-slider
      v-model="configs.descMarginTop"
      show-input
      :max="100"
      @input="setValue('descMarginTop')"
    >
    </el-slider>

    <h3>內容體上間距</h3>
    <el-slider
      v-model="configs.itemsMarginTop"
      show-input
      :max="100"
      @input="setValue('itemsMarginTop')"
    >
    </el-slider>
    <h3>內容間距</h3>
    <el-slider
      v-model="configs.itemMarginTop"
      show-input
      :max="100"
      @input="setValue('itemMarginTop')"
    >
    </el-slider>
    <h3>項-底图配置</h3>
    <selfUpload
      v-model="configs.bgImg"
      type="default"
      @change="setValue('bgImg')"
    />
    <h3>項-上右下左间距</h3>
    <el-slider
      v-model="configs.paddingTop"
      show-input
      :max="500"
      @input="setValue('paddingTop')"
    >
    </el-slider>
    <el-slider
      v-model="configs.paddingRight"
      show-input
      :max="500"
      @input="setValue('paddingRight')"
    >
    </el-slider>
    <el-slider
      v-model="configs.paddingBottom"
      show-input
      :max="500"
      @input="setValue('paddingBottom')"
    >
    </el-slider>
    <el-slider
      v-model="configs.paddingLeft"
      :max="500"
      show-input
      @input="setValue('paddingLeft')"
    >
    </el-slider>
    <div class="cell-b">
      <h3>項-標題配置
        <selfTips :content="$tipText.textWrap" />
      </h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}title`]"
          :placeholder="`請輸入標題-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}title`)"
          type="textarea"
          rows="3"
        ></el-input>
      </div>

      <!-- <h3>上下间距</h3>
        <el-slider
          v-model="configs.titleLineHeight"
          show-input
          :max="100"
          @input="setValue('titleLineHeight')"
        >
        </el-slider> -->
    </div>
    <div class="cell-b">
      <h3>項-描述配置</h3>
      <div class="cell-b">
        <el-input
          v-for="lItem in language"
          :key="lItem.code"
          v-model="configs[`${lItem.code=='HK'?'':lItem.code}desc`]"
          :placeholder="`請輸入描述-${lItem.name}`"
          @input="setValue(`${lItem.code=='HK'?'':lItem.code}desc`)"
          type="textarea"
          rows="3"
        ></el-input>
      </div>

      <!-- <h3>上下间距</h3>
        <el-slider
          v-model="configs.descLineHeight"
          show-input
          :max="100"
          @input="setValue('descLineHeight')"
        >
        </el-slider> -->
    </div>
    <div class="cell-b">
      <h3>上下间距</h3>
      <el-slider
        v-model="configs.itemsLineHeight"
        show-input
        :max="100"
        @input="setValue('itemsLineHeight')"
      >
      </el-slider>
      <h3>項-內容配置</h3>
      <div
        v-for="item,index in configs.items"
        :key="index"
        class="item cell-b"
      >
        <selfCell title="標題">
          <div>
            <el-input
              v-for="lItem in language"
              :key="lItem.code"
              v-model="item[`${lItem.code=='HK'?'':lItem.code}title`]"
              style="margin-top:10px"
              :placeholder="`請輸入標題-${lItem.name}`"
              @input="setValue('items')"
              type="textarea"
              rows="3"
            ></el-input>
          </div>
        </selfCell>

        <selfCell title="描述">
          <div>
            <el-input
              v-for="lItem in language"
              :key="lItem.code"
              style="margin-top:10px"
              v-model="item[`${lItem.code=='HK'?'':lItem.code}desc`]"
              :placeholder="`請輸入描述-${lItem.name}`"
              @input="setValue('items')"
              type="textarea"
              rows="3"
            ></el-input>
          </div>
        </selfCell>
      </div>
    </div>
    <div class="cell-b">
      <h3>項-左间距</h3>
      <el-slider
        v-model="configs.marginLeft"
        show-input
        :max="500"
        @input="setValue('marginLeft')"
      >
      </el-slider>
      <h3>項-图片配置</h3>
      <selfUpload
        v-model="configs.image"
        type="default"
        @change="setValue('image')"
      />
      <el-input
        v-model="configs.alt"
        type="textarea"
        @change="setValue('alt')"
        placeholder="請輸入圖片描述"
      />
    </div>

  </div>
</template>

<script>
import langEditMixin from '../../mixins/langEdit'
import editImage from "./image.vue"
export default {
  name: "edit-text-ricePOS",
  mixins: [langEditMixin],
  components: {
    editImage,
  },
  props: {
    index: {
      default () {
        return '';
      },
    },
    configs: {
      default () {
        return {
        };
      },
    },
  },
  methods: {
    setValue (key) {
      if (key == 'src') key = 'items'
      this.$emit('setValue', { key: key, value: this.configs[key] });
    },
  },
}
</script>

<style lang="less" scoped>
.edit-text-ricePOS-box {
}
</style>